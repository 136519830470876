<template>
  <div class="permit_info">
    <div class="head">{{processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import'}} Customs Permit</div>
    <div class="body">
      <div class="user">
        <figure class="avatar table"><img :src="permitLatest.updateUserIcon"></figure>
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">{{permitLatest.updateUserName}}</div>
          <div class="from_name">{{permitLatest.updateUserName}}</div>
        </el-tooltip>
      </div>
      <span>Declaration No {{permitLatest.declarationNo}}</span>
      <div class="permit_download">
        <figure @click="txtDownload"><img src="@/assets/images/icons/icon_cloud-download.svg"></figure>
        <figure v-if="attachmentFile && (attachmentFile.documentManageId || attachmentFile.pdfFilePath)" class="pdf" @click="pdfDownload"><img src="@/assets/images/icons/icon_attach_file.svg"></figure>
      </div>
    </div>
    <img v-if="isFromPic" class="close_times" src="@/assets/images/icons/times_gray600.svg" @click.stop.prevent="removePermit">
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { PROCESS_TYPE } from 'lib-tw-common';

export default {
  name: 'TwPermitLinking',
  components: {},
  props: {
    processType: String,
    permitLatest: {
      type: Object,
      default: () => []
    },
    isFromPic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      PROCESS_TYPE: PROCESS_TYPE,
    };
  },
  computed: {
    attachmentFile() {
      return _.get(this.permitLatest, 'attachmentFile', {});
    },
  },
  created() {},
  methods: {
    removePermit() {
      this.$emit('remove-permit');
    },
    // 許可書TXT of PDFダウンロード
    txtDownload() {
      const declarationNo = _.get(this.permitLatest, 'declarationNo');
      const declarationDate = _.get(this.permitLatest, 'declarationDate');
      const declarationTrx = _.get(this.permitLatest, 'declarationTrx');
      // 許可書詳細取得BFFコールし、permitFilePathを取得後、許可書詳細ダウンロード
      // bff_ncp_3 輸出許可書プール詳細照会BFF
      // bff_ncp_7 輸入許可書プール詳細照会BFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/detail' : 'get_/v1/nc-pool-datas/import/detail';
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: apiCode,
          query: {
            declarationNo: declarationNo,
            declarationDate: declarationDate,
            trx: declarationTrx,
          }
        }
      };
      $api.request(params)
      .then(res => {
        const fileName = this.$options.filters.fileName(res.permitFilePath) || `${declarationNo}.txt`;
        // 許可書詳細ダウンロード
        this.permitDownload(declarationNo, declarationDate, declarationTrx, fileName)
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 許可書詳細ダウンロードBFF
    permitDownload(declarationNo, declarationDate, trx, fileName, type = 'TXT') {
      const query = {
        declarationNo: declarationNo,
        declarationDate: declarationDate,
        trx: trx,
        downloadFileType: type, // TXT or PDF
      }

      // bff_ncp_4 輸出許可書プール詳細ダウンロードBFF
      // bff_ncp_8 輸入許可書プール詳細ダウンロードBFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/download' : 'get_/v1/nc-pool-datas/import/download';
      $api
      .txtDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // PDFダウンロード
    pdfDownload() {
      // 紐付け前
      if (this.attachmentFile.pdfFilePath) {
        const declarationNo = _.get(this.permitLatest, 'declarationNo');
        const declarationDate = _.get(this.permitLatest, 'declarationDate');
        const declarationTrx = _.get(this.permitLatest, 'declarationTrx');
        const fileName = this.attachmentFile.pdfFileName || this.$options.filters.fileName(this.attachmentFile.pdfFilePath);
        // const pdf = true;
        // 許可書詳細ダウンロード
        this.permitDownload(declarationNo, declarationDate, declarationTrx, fileName, 'PDF')
      } else {
        // 紐付け後
        const path = {
          documentManageId: _.get(this.attachmentFile, 'documentManageId'),
          documentTrx: _.get(this.attachmentFile, 'documentTrx'),
        };
        const documentName = _.get(this.attachmentFile, 'documentName');
        $api
        .download(path, documentName)
        .then(() => {
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.permit_info {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
    color: $color_gray_800;
    height: 52px;

    .head {
      background: $color_gray_300;
      border-radius: 6px 0px 0px 6px;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      padding: 0 16px;
      height: 100%;
    }

    .body {
      flex: 1;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid $color_gray_300;
      border-radius: 0px 6px 6px 0px;
      height: 100%;
      padding-left: 19px;
    }

    .user {
      display: flex;
      align-items: center;
      margin-right: 32px;

      font-size: 14px;
      line-height: 20px;
      color: $color_black;

      .from_name {
        max-width: 320px;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close_times {
      position: absolute;
      right: 12px;
      top: 14px;
      cursor: pointer;
      &:hover {
        opacity: .5;
      }
    }

    .permit_download {
      margin: 0 56px 0 auto;
      display: flex;
      figure {
        cursor: pointer;
        padding: 8px;
        margin: 0 4px 0;
        &:hover {
          opacity: .7;
        }
        > img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
</style>
